<template>
    <div class="con-wrap">
        <CarrotTitle title="CEO Message">
            - 첨부파일은 최대 50MB까지 파일을 올리실 수 있습니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <!-- <tr>
                                <th>상단고정</th>
                                <td>
                                    <label><input type="checkbox" name="topfix" v-model="bbs.topfix"><span class="ml-5"> 사용</span></label>
                                </td>
                            </tr> -->
                            <tr>
                                <th><span class="txt-red">*</span> 언어</th>
                                <td>
                                    <label><input type="radio" v-model="bbs.ca_name" value="KO"> 국문</label>
                                    <label class="ml-20"><input type="radio" v-model="bbs.ca_name" value="EN"> 영문</label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 제목</th>
                                <td>
                                    <input type="text" v-model="bbs.title" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 내용</th>
                                <td>
                                    <!-- <textarea v-model="bbs.content" class="w-100per h-300px"></textarea> -->
                                    <CarrotEditor etype="ceomessage" v-model="bbs.content" class="w-100per h-400px"></CarrotEditor>
                                </td>
                            </tr>
                            <tr>
                                <th>파일첨부 #1</th>
                                <td>
                                    <a v-if="bbs.file1_name" :href="bbs.file1_url" class="btn-view" target="_blank">{{ bbs.file1_name }}</a>
                                    <label v-if="bbs.file1_name" class="ml-10"><input type="checkbox" v-model="bbs.dchk1"> 삭제</label>
                                    <CarrotFileUploader ref="file1"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr>
                                <th>파일첨부 #2</th>
                                <td>
                                    <a v-if="bbs.file2_name" :href="bbs.file2_url" class="btn-view" target="_blank">{{ bbs.file2_name }}</a>
                                    <label v-if="bbs.file2_name" class="ml-10"><input type="checkbox" v-model="bbs.dchk2"> 삭제</label>
                                    <CarrotFileUploader ref="file2" class="mt-5"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr>
                                <th>파일첨부 #3</th>
                                <td>
                                    <a v-if="bbs.file3_name" :href="bbs.file3_url" class="btn-view" target="_blank">{{ bbs.file3_name }}</a>
                                    <label v-if="bbs.file3_name" class="ml-10"><input type="checkbox" v-model="bbs.dchk3"> 삭제</label>
                                    <CarrotFileUploader ref="file3" class="mt-5"></CarrotFileUploader>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="bbs.showList">목록</button>
                    <button class="btn-default float-right mt-30" @click="bbs.onSave">수정</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotEditor from '@/components/common/CarrotEditor.vue'


export default {
    layout:"CARROTZone",
    components: {
        CarrotEditor
    },
    setup() {
        const router = useRouter();
        const route  = useRoute();
        const toast = useToast();

        const file1 = ref(null);
        const file2 = ref(null);
        const file3 = ref(null);
        const bbs = reactive({
            idx     : 0,
            topfix  : false,
            ca_name : 'KO',
            title   : "",
            content : "",

            file1_name : "", file1_url : "",
            file2_name : "", file2_url : "",
            file3_name : "", file3_url : "",

            dchk1 : false, dchk2 : false, dchk3 : false, 

            showList: () => {
                router.push({ name:"CARROTZone-CEOMessageList" });
            },

            getInfo: () => {
                let params = {
                    idx : bbs.idx
                };
                axios.get("/rest/carrotzone/CEOMessageView", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.topfix  = res.data.topfix=="Y"?true:false;
                        bbs.ca_name = res.data.category;
                        bbs.title   = res.data.title;
                        bbs.content = res.data.content;

                        bbs.file1_name = res.data.file1_name;
                        bbs.file1_url  = res.data.file1_url;
                        bbs.file2_name = res.data.file2_name;
                        bbs.file2_url  = res.data.file2_url;
                        bbs.file3_name = res.data.file3_name;
                        bbs.file3_url  = res.data.file3_url;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onSave: () => {
                if( !bbs.ca_name ){
                    toast.error("카테고리를 선택하세요.");
                    return;
                }
                if( !bbs.title ){
                    toast.error("제목을 입력하세요.");
                    return;
                }
                if( !bbs.content ){
                    toast.error("내용을 입력하세요.");
                    return;
                }
                var formData = new FormData();
                formData.append("idx", bbs.idx);
                formData.append("topfix", bbs.topfix==true?"Y":"N");
                formData.append("ca_name", bbs.ca_name);
                formData.append("title", bbs.title);
                formData.append("content", bbs.content);
                if(file1.value.file.filedata!==null) {
                    formData.append("nfile1", file1.value.file.filedata);
                }
                if(file2.value.file.filedata!==null) {
                    formData.append("nfile2", file2.value.file.filedata);
                }
                if(file3.value.file.filedata!==null) {
                    formData.append("nfile3", file3.value.file.filedata);
                }
                formData.append("dchk1", bbs.dchk1==true?"Y":"N");
                formData.append("dchk2", bbs.dchk2==true?"Y":"N");
                formData.append("dchk3", bbs.dchk3==true?"Y":"N");

                axios.post('/rest/carrotzone/ceomessageMod', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{
                    if( res.data.err == 0 ){
                        // console.log(res.data);
                        // location.href = "/CARROTZone/MediaZoneView/" + bbs.idx;
                        router.push({
                            name   : 'CARROTZone-CEOMessageView-idx',
                            params : { idx:bbs.idx }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            }
        });
        
        onMounted(() => {
            // Mounted
            bbs.idx = route.params.idx;
            if ( !bbs.idx ) {
                router.back(-1);
            }

            bbs.getInfo();
        });

        onUnmounted(() => { 
            // UnMounted
        });
        return {bbs, file1, file2, file3};
    }
}
</script>

<style lang="scss" scoped>
</style>